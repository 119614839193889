import React, {Suspense, useEffect, useState} from "react";
import {config} from "../../Constants";
import Hotel from "../../assets/img/bg/an-img-01.png";
import Hotel1 from "../../assets/img/icon/fe-icon01.png"
import {Link, useLocation} from "react-router-dom";
import {LinearProgress, Box, Typography, Chip, Divider} from "@mui/material";
import {Container, Modal, Button, Row, Col} from 'reactstrap';
import Grid from "@mui/material/Grid";



const Main = (props) => {
    let url = config.url.API_URL;

    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const {checkInDate, checkOutDate, adults, children, rooms} = location.state;
    const [availableVacantRoomCategoryList, setAvailableVacantRoomCategory] = useState([]);
    const clientBookingUrl = `${url}/api/v1/create_client_and_booking/`;

    const [show, setShow] = useState(false);
    const [roomCategoryTitle, setRoomCategoryTitle] = useState(0);
    const [roomCategoryId, setRoomCategoryId] = useState(0);

    const [submissionStatus, setSubmissionStatus] = useState(false);
    const [roomOccupancy, setRoomOccupancy] = useState({});
    const [bookings, setBookings] = useState([]);
    const [roomCount, setRoomCount] = useState(rooms);
    const [bookingLimit, setBookingLimit] = useState(true);

    const bookingPostBody = {
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        adults: adults,
        roomOccupants: bookings

    }

    const handleShow = (roomCategoryId, roomCategoryTitle) => {
        setRoomCategoryId(roomCategoryId);
        setRoomCategoryTitle(roomCategoryTitle);

        setShow(true);
    }

    const handleClose = () => setShow(false);


    const onRoomOccupancyChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setRoomOccupancy(roomOccupancy => ({
            ...roomOccupancy, [name]: value, roomCategoryId: roomCategoryId, roomCategoryTitle: roomCategoryTitle
        }));

    }

    const handleSaveRoomOccupant = () => {

        if (roomCount === 0) {
            setBookingLimit(false);
        } else {
            setBookings(bookings => [...bookings, roomOccupancy])
            setRoomCount(roomCount - 1)
        }

        setShow(false);

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch(clientBookingUrl, requestMetadata)
            .then(res => res.json())
            .then((data) => {
                    setSubmissionStatus(true);
                    setBookings([]);
                },
                (error) => {
                    setSubmissionStatus(false);
                }
            )

    }


    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingPostBody)
    }

    useEffect(() => {
        fetch(`${url}/api/v1/get_vacant_room_categories/`)
            .then(res => res.json())
            .then((data) => {
                setAvailableVacantRoomCategory(data);
                setIsLoaded(true);
            }, (error) => {
                setIsLoaded(true);
            })
    }, []);

    return (
        <>
            <section className="booking pt-120 pb-120 p-relative fix">
                <Container component="available-rooms" maxWidth="xs">
                    <Box sx={{
                        marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
                    }}>
                        <div className="animations-01"><img src={Hotel} alt="an-img-01"/></div>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="contact-bg02">

                                        <Box sx={{flexDirection: 'column'}}>
                                            <Typography variant="h6">
                                                Checkin Date: {checkInDate}
                                            </Typography>
                                            <Typography variant="h6">
                                                Checkout Date: {checkOutDate}
                                            </Typography>
                                        </Box>

          
                                        <Divider variant="middle">
                                            <Chip className="primary"
                                                  label="Select your rooms and add occupants"></Chip>
                                        </Divider>

                                        {(!isLoaded) ? (<LinearProgress/>) :
                                            (<Suspense fallback={<div>Loading...</div>}>
                                                {availableVacantRoomCategoryList.map((item, index) => (
                                                    <>
                                                    <Row style={{marginTop:"20px"}}>
                                                        <Col item xs={12} md={3}>
                                                            <img src={Hotel1}/>
                                                        </Col>

                                                        <Col item xs={6} md={4}>
                                                     
                                                            <Row>
                                                                <h4>{"Room Type: " + item.title} </h4>
                                                            </Row>
                                                            <Row>
                                                            <h4>{"Room Price: " + item.currency} {item.price.toLocaleString()}</h4>
                                                            </Row>
                                                        </Col>

                                                        <Col item xs={6} md={2}>
                                                        <Row>
                                                            <h4>{"Rooms Left: " + item.room_count}</h4>
                                                            </Row>
                                                            <Row>
                                                            <Link onClick={() => handleShow(item.id, item.title)}
                                                                   type="button"
                                                                   className="top-btn mt-7 mb-10">Select Room</Link>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Divider variant="middle">
                                            <Chip className="primary"></Chip>
                                        </Divider>
                                                    </>
                                                    

                                                ))}
                                            </Suspense>)
                                        }

                                        <Modal>
                                            <Modal.Header closeButton onClick={handleClose}>
                                                <Modal.Title>Booking Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <>
                                                    <form method="post" onSubmit={handleSubmit}
                                                          className="contact-form mt-30">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="contact-field p-relative c-name mb-20">
                                                                    <label><i className="fal fa-user"></i> First
                                                                        Name</label>
                                                                    <input type="text" id="firstName" name="firstName"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-user"></i> Last
                                                                        Name</label>
                                                                    <input type="text" id="lastName" name="lastName"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-phone"></i> Phone
                                                                        Number</label>
                                                                    <input type="phone" name="phoneNumber"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div
                                                                    className="contact-field p-relative c-subject mb-20">
                                                                    <label><i className="fal fa-mailbox"></i> e-Mail
                                                                    </label>
                                                                    <input type="email" name="email"
                                                                           onChange={onRoomOccupancyChange}/>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div
                                                                    className="col-xl-12 col-lg-12 d-none d-lg-block mt-4">
                                                                    <Link onClick={handleSaveRoomOccupant} type="button"
                                                                       className="top-btn mt-7 mb-10">Save Room
                                                                        Occupant</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="contained" color="error"
                                                        onClick={handleClose}>Close</Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Container>
            </section>
        </>
    )

}

export default Main;