import {Link} from 'react-router-dom'
import React from 'react'
import BGImg from "../../assets/img/bg/bdrc-bg.jpg";
import AboutUsContent from "../elements/AboutUsContent/Main";
import BGVideo from "../../assets/img/bg/video-bg.png";

const Main = () => {
  return (
    <>
        <main>
            <section className="breadcrumb-area d-flex align-items-center" style={{ background : `url(${BGImg})`}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-center">
                                <div className="breadcrumb-title">
                                    <h2>About</h2>    
                                    <div className="breadcrumb-wrap">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">About</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02"><img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-02"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">

                        <AboutUsContent/>

                    </div>
                </div>
            </section>
            <section id="skill" className="skill-area p-relative fix" style={{background:"#291d16"}}>
                <div className="animations-01"><img src="assets/img/bg/an-img-05.png" alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
					   <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="skills-content s-about-content">
                                <div className="skills-title pb-20">                               
                                    <h5>City Inn Offerings</h5>
                                    <h2>
                                    We Offer a wide selection of Hospitality services
                                    </h2>                             
                                </div>
                                <p>Our experience in the hotel business is unmatched</p>
                                <div className="skills-content s-about-content mt-20">
                                    <div className="skills">
                                        <div className="skill mb-30">
                                        <div className="skill-name">Quality room service</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="80" style={{width: "80%"}}></div>
                                        </div>
                                        </div>
                                        <div className="skill mb-30">
                                        <div className="skill-name">Well maintained premises</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="90" style={{width: "90%"}}></div>
                                        </div>
                                        </div>
                                        <div className="skill mb-30">
                                        <div className="skill-name">Hospitality</div>
                                        <div className="skill-bar">
                                            <div className="skill-per" id="93" style={{width: "93%"}}></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                            <div className="skills-img wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">                              
                                <img src={BGVideo} alt="img" className="img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-area2 p-relative fix" style={{background: "#f7f5f1"}}>
                 <div className="animations-02"><img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 pr-30">
                           <div className="feature-img">                               
                                <img src={BGVideo} alt="img" className="img"/>
                            </div>
                        </div>
					   <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="feature-content s-about-content">
                                <div className="feature-title pb-20">                               
                                    <h5>Luxury Hotel & Resort</h5>
                                <h2>
                                 Why City Inn
                                </h2>                             
                                </div>
                                <p>Welcome to City Inn, a modern and welcoming hotel located in the vibrant heart of Kinondoni, Dar es Salaam. 
                                    Our hotel offers a selection of spacious, well-appointed rooms designed with your comfort in mind, complete with all the amenities needed for a relaxing and enjoyable stay. Whether you're visiting for business or leisure, our friendly and attentive staff are committed to making your experience as comfortable and seamless as possible.
                                   Beyond our cozy rooms, we provide an array of on-site amenities to enhance your stay. Enjoy delicious meals at our restaurant, prepared with fresh, locally sourced ingredients, or unwind with a refreshing drink at our well-stocked bar. 
                                   Take a peaceful stroll through our beautifully landscaped garden, or stay productive in our fully equipped business center.
                                    At City Inn, we are dedicated to making your visit to Dar es Salaam truly unforgettable. Our prime location provides easy access to the city's top attractions, including the National Museum of Tanzania, Kivukoni Fish Market, and the Dar es Salaam International Conference Centre. Book your stay today and experience the perfect blend of comfort, convenience, and hospitality at City Inn.</p>
                                <div className="slider-btn mt-15">                                          
                                    <Link to ="/about" className="btn ss-btn smoth-scroll">Discover More</Link>				
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="newslater-area p-relative pt-120 pb-120" style= {{background: "#f7f5f1"}}>
                <div className="animations-01"><img src="assets/img/bg/an-img-07.png" alt="contact-bg-an-05"/></div>
                <div className="container">
                    <div className="row justify-content-center align-items-center text-center">
                        <div className="col-xl-9 col-lg-9">
                            <div className="section-title center-align mb-40 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                 <h5>Newsletter</h5>
                                <h2>
                                    Get the best offers the Hotel has to offer
                                </h2>
                                <p>Enjoy unlimited hotel discounts by subscribing</p>
                            </div>
                            <form name="ajax-form" id="contact-form4" action="#" method="post" className="contact-form newslater">
                               <div className="form-group">
                                  <input className="form-control" id="email3" name="email" type="email" placeholder="Your Email Address" value="" required=""/> 
                                  <button type="submit" className="btn btn-custom" id="send2">Subscribe Now</button>
                               </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}

export default Main