import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/img/logo/citi-inn-logo.png";
import QrCode from "../../assets/img/qr/qr-code.jpg";
import { Modal } from "@mui/material";
import { config } from "../../Constants";
import styled from "styled-components";
import BasicButton from "../../component/elements/Buttons/BasicButton/Main";
import { Button } from "reactstrap";


const Main = () => {
  let url = config.url.API_URL;
  const [mobile, setmobile] = useState(false);
  const [show, setshow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);

  const LoginButton = styled.button`
    background-color: #be9874;
    color: white;
    margin: 10px 0;
    padding: 12px 40px 12px 40px;
    font-size: 13px;
    font-family: Roboto, sans-serif;
  `;

  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 d-none d-lg-block">
                <div className="header-cta">
                  <ul>
                    <li>
                    <div className="col-xl-1 col-lg-1">
                  <div className="logo">
                    <Link to="/">
                      <img src={QrCode} alt="qr-code" />
                    </Link>
                  </div>
                </div>
                    </li>
                    <li>
                      <i className="far fa-envelope"></i>
                      <span>info@citi-inn.com</span>
                    </li>
                    <li>
                      <i className="far fa-mobile"></i>
                      <strong>0762 737423</strong>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 d-none d-lg-block text-right">
                <div className="header-social">
                  <span>
                    <Link to="#" title="Facebook">
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link to="#" title="LinkedIn">
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-twitter"></i>
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-youtube"></i>
                    </Link>
 
                    <a href={url+"/admin"} target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-lock"></i>
                    </a>

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2">
                  <div className="logo">
                    <Link to="/">
                      <img src={Logo} alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8">
                  <div className="main-menu text-center">
                    <nav id="mobile-menu ">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/room-details">Accomodation</Link>
                        </li>
                        <li>
                          <Link to="/manage-bookings">Manage Bookings</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                {/* <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                  <Link to="/manage-bookings" className="top-btn mt-10 mb-10">
                    reservation{" "}
                  </Link>
                </div> */}

                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <Link
                        to="#nav"
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        onClick={() =>
                          setmobile(mobile === true ? false : true)
                        }
                        style={{
                          right: "0px",
                          left: "auto",
                          textAlign: "center",
                          textIndent: "0px",
                          fontSize: "18px",
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <>
                            <span>
                              <span>
                                <span></span>
                              </span>
                            </span>
                          </>
                        )}
                      </Link>
                      <nav className="mean-nav">
                        {mobile && (
                          <ul style={{ display: mobile ? "block" : "none" }}>
                             <li>
                              <Link to="/">Home</Link>
                            </li>
                            <li>
                              <Link to="/about">About</Link>
                            </li>
                            <li>
                              <Link to="/room-details">Accomodation</Link>
                            </li>
                            <li>
                              <Link to="/manage-bookings">Manage Bookings</Link>
                            </li>
                            <li>
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        )}
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Main;
